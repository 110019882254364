import Members from '../../json/members.json'

export default function About() {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 text-center gap-8 my-5">
            {
                Members.members.map((member) => 
                    <div key={member.name}>
                        <img src={member.image_url} alt={member.name} className="mx-auto mb-1 rounded-3xl shadow-2xl" />
                        <h3 className="uppercase font-bold underline mb-1">{member.name}</h3>
                        <h4 className=" mb-2">| {member.instrument} |</h4>
                        <div className="text-justify">
                        <p>{member.bio}</p>
                        </div>
                    </div>
                )
            }
        </div>
    );
}
