import Band from '../../images/home.jpg'

export default function Home() {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 text-justify gap-x-5 gap-y-5 my-5">
            <div>
                <img src={Band} alt="home pic" className="rounded-2xl" />
            </div>
            <div>
                <p>Thank you for visiting our website. Based in Mid Wales and the Welsh Marches we are an experienced Trio of 
                folk musicians who are available to play locally or nationally at anytime. The band members have played at festivals and events in the UK 
                and abroad in such places as Austria, America, Belgium and France.

                Using fiddle, mandolin/cittern and acoustic guitar we play an eclectic 
                mix of driving traditional music. Specialising in American Old Time, Welsh, Irish and French Canadian styles, we also play music of our own 
                composition, and even a Czech tune!

                We regularly play for Ceilidhs, Barn Dances and Twmpathau, working with a number of different Callers. We are also available for 
                performances playing arranged tunes and folk songs.

                If you would like to know more, feel free to email or phone us to 
                discuss your requirements. We can arrange a Caller appropriate for your  event, be it a Ceilidh, Barn Dance or Twmpath. (Twmpath called in Welsh if desired)</p>
            </div>
        </div>
    );
}