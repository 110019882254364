import React, { useState } from "react";
import Logo from '../images/canol-logo.png'
import MenuIcon from '../images/menu-icon.png'
import { GrPhone, GrMail } from 'react-icons/gr'
import Item from '../components/navbar/Item'

export default function Navbar() {
    const [navbarOpen, setNavbarOpen] = useState(false);
    
    return (
		<div className="sticky top-0 z-50 shadow-lg bg-canol-gray">
			<nav className="border-b-2 border-canol-sandy">
				<div className="w-full relative flex items-center bg-canol-sandy">
					<GrPhone />
					<a className="ml-2" href="tel:01978 502 469">01978 502 469</a>
					<div className="w-10"></div>
					<GrMail />
					<a className="ml-2" href="mailto:info@canolmusic.co.uk">info@canolmusic.co.uk</a>
				</div>
				
				<div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
					<div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
						<a className="text-sm  lg:text-lg font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap" href="/home">
							<img src={Logo} alt="Logo" />
							<p className="shadow-2xl">Lively Traditional Music</p>
						</a>
						<button
							className="text-black cursor-pointer text-lg mt-16 pt-16 leading-none px-3 py-1 rounded bg-transparent block lg:hidden outline-none focus:outline-none"
							type="button"
							onClick={() => setNavbarOpen(!navbarOpen)}
						>
							<i className="fas fa-bars"><img className="w-8" src={MenuIcon} alt="Menu"/></i>
						</button>
					</div>
					<div className={"lg:flex flex-grow items-center" + (navbarOpen ? " flex" : " hidden")}>
					<ul className="flex flex-col lg:flex-row list-none lg:ml-auto lg:mt-20 lg:pt-20">
						<Item text="home" />
						<Item text="about" />
						<Item text="listen" />
					</ul>
					</div>
				</div>
			</nav>
		</div>
    );
}
