import Music from '../../json/music.json'

export default function Listen() {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2 text-center gap-5 gap my-5">
            {
                Music.tracks.map((track) =>
                    <div key={track.number} className="bg-canol-sandy p-1 lg:p-5 rounded-lg shadow-2xl">
                        <p className="mb-3">{track.title}</p>
                        <audio controls className="mx-auto lg:mx-auto">
                            <source src={track.url} type="audio/mp3" title={track.title} />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                )
            }
        </div>
    );
}
