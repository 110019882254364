const Item = (props) => {
    return (
        <li className="nav-item">
            <a
                className="px-3 py-2 flex items-center uppercase font-bold leading-snug text-black hover:text-canol-sandy hover:opacity-75"
                href={"/" + props.text}
                id={props.text}
            >
                <span className="ml-2 text-sm lg:text-lg">{props.text}</span>
            </a>
        </li>
    )
}

export default Item