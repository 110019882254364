import './App.css';
import Wrapper from './components/Wrapper'

function App() {
    return (
        <div className='bg-canol-gray'>
        <Wrapper />
        </div>
    );
}

export default App;
