import React from "react";
import Navbar from './Navbar'
import Home from './pages/Home'
import About from "./pages/About"
import Listen from "./pages/Listen"
import Footer from "./Footer";

class Wrapper extends React.Component {
	// constructor(props) {
	// 	super(props);
	// }

	render() {
		let Component
		switch (window.location.pathname) {
		case "/home":
			Component = <Home />
			break
		case "/about":
			Component = <About />
			break
		case "/listen":
			Component = <Listen />
			break
		default:
			Component = <Home />
		}
		return (
			<div>
				<Navbar />
					<div className="container flex flex-col mx-auto p-6 w-auto">
						{Component}
					</div>
				<Footer />
			</div>
		)
	}
}

export default Wrapper
  